/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "b28ed1aca2284036a29d2ca1b338688e",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "b28ed1aca2284036a29d2ca1b338688e",
            "region": "us-east-1"
        }
    },
    "aws_cognito_identity_pool_id": "us-east-1:d899c652-50ee-42e5-813b-14206a3f47ec",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_oQHl0CGAS",
    "aws_user_pools_web_client_id": "74psk8f6akedor5e0hc56sb1kt",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "cepheid-scow-hosting-bucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d239tjggeocmtz.cloudfront.net",
    "aws_user_files_s3_bucket": "cepheid-scow-main-bucket112140-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
