import { useState, useRef, useEffect } from 'react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import AppLayout from "@cloudscape-design/components/app-layout";
import FormField from "@cloudscape-design/components/form-field";
import Alert from "@cloudscape-design/components/alert";
import Container from "@cloudscape-design/components/container";
import Button from "@cloudscape-design/components/button";
import TokenGroup from "@cloudscape-design/components/token-group";
import Header from "@cloudscape-design/components/header";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import SpaceBetween from "@cloudscape-design/components/space-between";
import ProgressBar from "@cloudscape-design/components/progress-bar";
import Amplify from '@aws-amplify/core';
import { Auth, Storage } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Heading, useTheme } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Analytics } from "aws-amplify";
import awsconfig from './aws-exports';
import { Hub } from 'aws-amplify';
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";
import { FieldGroupIconButton } from '@aws-amplify/ui-react';
import { MdInfo } from 'react-icons/md';
import { Text } from '@aws-amplify/ui-react';
import { SelectField } from '@aws-amplify/ui-react';
import { applyTheme } from '@cloudscape-design/components/theming';
import { brandTheme as theme } from "./theme.ts";
import Form from "@cloudscape-design/components/form";
import * as React from "react";
import Input from "@cloudscape-design/components/input";
import Select from "@cloudscape-design/components/select";
import DatePicker from "@cloudscape-design/components/date-picker";
import TimeInput from "@cloudscape-design/components/time-input";
import Checkbox from "@cloudscape-design/components/checkbox";


Amplify.configure(awsconfig);



const { reset } = applyTheme({ theme });

const listener = async (data) => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const user = await Auth.currentAuthenticatedUser();
    console.log('current auth user', user);
    const userName = user.username;
    switch (data.payload.event) {
        case 'signIn':
            console.log('user signed in');
            console.log('ScowUserLogIn : ', userName + ' ' + ' ' + today.toUTCString());
            await Analytics.record({
                name: "ScowUserLogIn",
                attributes: {
                    userLogIn: userName + ' | ' + today.toUTCString(),
                },
                immediate: true,
            });
            break;
    }
};

Hub.listen('auth', listener);

const navigationHide = true;
const toolsHide = true;
let uploadCompleted = [];
let timer;
let logOutTimer;


function formatBytes(a, b = 2, k = 1024) {
    let d = Math.floor(Math.log(a) / Math.log(k));
    return 0 === a ? "0 Bytes" : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d];
}

const Content = (props) => {
    const hiddenFileInput = useRef(null);
    const [visibleAlert, setVisibleAlert] = useState(false);
    const [uploadList, setUploadList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [historyList, setHistoryList] = useState([]);
    const [historyCount, setHistoryCount] = useState(0);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const { tokens } = useTheme();

    const handleModalTimer = () => {
        timer = setTimeout(() => {
            // clears any pending timer.
            resetTimer();
            // displays session timeout message
            logOutTimer = setTimeout(() => {
                setPopupOpen(false);
                logoutAction();
            }, 60000);
            setPopupOpen(true);
        }, 840000);
    };

    async function resetTimeOutTimer() {
        await resetLogOutTimer();
        handleModalTimer();
        console.log('resetTimeoutTimer called');
        console.log('log out timer is', logOutTimer);
        setPopupOpen(false);
    }

    // this resets the timer if it exists.
    const resetTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }
    };

    const resetLogOutTimer = () => {
        if (logOutTimer) {
            clearTimeout(logOutTimer);
        }
    };

    // logs out user 
    const logoutAction = async () => {
        await Auth.signOut({ global: true }).then(() => {
            window.location.reload();
        });
    };

    const handleUploadProgressStatus = (status) => {
        props.onSelectLogOut(status);
    };

    useEffect(() => {
        resetTimer();
        handleModalTimer();
    }, []);


    const { user } = useAuthenticator((context) => [context.user]);

    const handleClick = () => {
        setUploadList([]);
        hiddenFileInput.current.value = ""; // This avoids errors when selecting the same files multiple times
        hiddenFileInput.current.click();
        resetTimer();
        handleModalTimer();
    };

    const handleChange = e => {
        e.preventDefault();
        let i, tempUploadList = [];
        for (i = 0; i < e.target.files.length; i++) {
            tempUploadList.push({
                label: e.target.files[i].name,
                labelTag: formatBytes(e.target.files[i].size),
                icon: 'file',
                id: i
            })
        }
        setUploadList(tempUploadList);
        setFileList(e.target.files);
        resetTimer();
        handleModalTimer();
    };

    function uploadFailed(fileId) {
        let tempHistoryList = historyList;
        tempHistoryList.filter(element => element.fileObject === fileId).forEach(item => item.status = 'failed');
        setHistoryList(tempHistoryList);
        console.log('Failed upload : Updated History list');
    }

    async function recordUploadActionByUser(fileName) {
        const timeElapsed = Date.now();
        const today = new Date(timeElapsed);
        const username = user.username;
        console.log('ScowUserUploadcAction : ', username + ' ' + fileName + ' ' + today.toUTCString());
        await Analytics.record({
            name: "ScowUserUploadcAction",
            attributes: {
                userFileUploadAction: user.username + ' | ' + fileName + ' | ' + today.toUTCString(),
            },
        });
    }

    function progressBarFactory(fileObject) {
        let localHistory = historyList;
        const id = localHistory.length;
        localHistory.push({
            id: id,
            percentage: 0,
            filename: fileObject.name,
            filesize: formatBytes(fileObject.size),
            status: 'in-progress',
            fileObject: fileObject
        });

        setHistoryList(localHistory);
        return (progress) => {
            let tempHistory = historyList.slice();
            const percentage = Math.round((progress.loaded / progress.total) * 100);
            tempHistory[id].percentage = percentage;
            if (percentage === 100) {
                tempHistory[id]['status'] = 'success';
            }
            setHistoryList(tempHistory);
        };
    }

    const handleUpload = () => {
        resetTimer();
        if (uploadList.length === 0) {
            setVisibleAlert(false);
        }
        else {
            console.log('Uploading files to S3');
            setUploadInProgress(true);
            handleUploadProgressStatus(true);
            let i, progressBar = [];
            //let uploadCompleted = [];
            for (i = 0; i < uploadList.length; i++) {
                // If the user has removed some items from the Upload list, we need to correctly reference the file
                const id = uploadList[i].id;
                progressBar.push(progressBarFactory(fileList[id]));
                setHistoryCount(historyCount + 1);
                uploadCompleted.push(Storage.put('Cepheid-SCOW-Data-Archive/' + fileList[id].name, fileList[id], {
                    progressCallback: progressBar[i],
                    level: "public",
                }).then(async result => {
                    console.log(`Completed the upload of ${result.key}`);
                    await recordUploadActionByUser(fileList[id].name);

                }).catch(async err => {
                    console.log(`Upload Failed  ${err}`);
                    await uploadFailed(fileList[id]);
                }));
            }

            // When you finish the loop, all items should be removed from the upload list
            Promise.all(uploadCompleted)
                .then(() => {
                    setUploadList([]);
                    setUploadInProgress(false);
                    handleUploadProgressStatus(false);
                    resetTimer();
                    handleModalTimer();
                });
        }
    };

    const handleDismiss = (itemIndex) => {
        setUploadList([
            ...uploadList.slice(0, itemIndex),
            ...uploadList.slice(itemIndex + 1)
        ]);
    };

    const List = ({ list }) => (
        <>
       
            {list.map((item) => (
                <div>
                {(item.status === 'failed') ? (<ProgressBar
                    key={item.id}
                    status={item.status}
                    value={item.percentage}
                    variant="standalone"
                    additionalInfo="0 bytes"
                    //description={item.filetype}
                    label={item.filename}
                />) :
                (<ProgressBar
                    key={item.id}
                    status={item.status}
                    value={item.percentage}
                    variant="standalone"
                    additionalInfo={item.filesize}
                    //description={item.filetype}
                    label={item.filename}
                />)
                }
                </div>
                
                
            ))}
        </>
    );
    
    const [value, setValue] = React.useState("");
    const [value1, setValue1] = React.useState("");
    const [value2, setValue2] = React.useState("");
    const [
    selectedOption,
    setSelectedOption
  ] = React.useState({ label: "Sample Source 1", value: "1" });
  
  
    const [
    selectedOptionType,
    setSelectedOptionType
  ] = React.useState({ label: "Sample Type 1", value: "5" });
  
  
   const [
    selectedOptionTemp,
    setSelectedOptionTemp
  ] = React.useState({ label: "4°C", value: "1" });
  
  const [
    selectedOptionMedia,
    setSelectedOptionmedia
  ] = React.useState({ label: "sample media 1", value: "1" });
  
  const [timeValue, setTimeValue] = React.useState("");
  
   const [dateValue, setDateValue] = React.useState("");
   
    const [timeValue1, setTimeValue1] = React.useState("");
  
   const [dateValue1, setDateValue1] = React.useState("");
    const [checked, setChecked] = React.useState(false);
    const [checked1, setChecked1] = React.useState(false);
    const [checked2, setChecked2] = React.useState(false);
    const [checked3, setChecked3] = React.useState(false);
    const [checked4, setChecked4] = React.useState(false);
    
    const [checked5, setChecked5] = React.useState(false);


const [checked6, setChecked6] = React.useState(false);


const [checked7, setChecked7] = React.useState(false);


const [checked8, setChecked8] = React.useState(false);


const [checked9, setChecked9] = React.useState(false);


const [checked10, setChecked10] = React.useState(false);


const [checked11, setChecked11] = React.useState(false);


const [checked12, setChecked12] = React.useState(false);


const [checked13, setChecked13] = React.useState(false);


const [checked14, setChecked14] = React.useState(false);


const [checked15, setChecked15] = React.useState(false);


const [checked16, setChecked16] = React.useState(false);


const [checked17, setChecked17] = React.useState(false);


const [checked18, setChecked18] = React.useState(false);


const [checked19, setChecked19] = React.useState(false);


const [checked20, setChecked20] = React.useState(false);



  
  


    return (
        <div class ="uploadcontainer">
        <SpaceBetween size="xxl">
            <Container
                disableHeaderPaddings
                id="s3-upload-multiple-objects"
                header={
                    <Header>
                     <Box variant="h3" fontWeight="bold" fontSize="heading-s" padding ={{top:"xs",left:"xl",bottom:"xs"}}>
                        Upload Files
                    </Box>
                    </Header>
                   
                }
            >
                {
                    <div>
                       <Box  padding="xs">
                       <SpaceBetween size="xxxs">
                        <Alert
                            onDismiss={() => setVisibleAlert(false)}
                            visible={visibleAlert}
                            dismissAriaLabel="Close alert"
                            dismissible
                            type="error"
                            header="No files selected"
                        >
                            You must select the files that you want to upload.
                        </Alert>
                        
                        <Box padding = {{top:"l"}}>
                        <FormField
                            label='File Upload'
                            description=<Box  color="text-body-secondary" fontSize="body-s" padding={{bottom:"xxs"}}>Click on the Choose file[s] button and select the files that you want to upload</Box>
                        />
                        </Box>

                        <SpaceBetween direction="horizontal" size="s">
                        { (uploadInProgress === true ) ? (
                            <Button disabled variant="default" className="btncolor"
                                    iconAlign="left"
                                    iconName="upload"
                            >
                                Choose file[s]
                            </Button>) : ( <Button onClick={handleClick}
                                    iconAlign="left"
                                    iconName="upload"
                            >
                                Choose file[s]
                            </Button>)
                            
                        }
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                style={{display: 'none'}}
                                multiple
                            />
                             <div>
                { ((uploadList.length === 0 ) ||  (uploadInProgress === true ) ) ? (
               <Button disabled variant="default" className="btncolor">Upload</Button>
                ) : (
                <Button variant="primary" onClick={handleUpload}>Upload</Button>)
                }
                </div>
                        </SpaceBetween>

                        <TokenGroup
                            onDismiss={({detail: {itemIndex}}) => {
                                handleDismiss(itemIndex)
                            }}
                            items={uploadList}
                            alignment="vertical"
                            limit={10}
                        />
                        </SpaceBetween>
                       </Box>
                    </div>
                }
                
                				<Modal
      visible={popupOpen}
      disableContentPaddings
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick = {resetTimeOutTimer}>Stay Logged In</Button>
          </SpaceBetween>
        </Box>
      }
      header={<Header>
                     <Box variant="h3" fontWeight="bold" fontSize="heading-s" padding ={{left:"xl",top:"xxxs",bottom:"xxxs"}}>
                        Session Timeout!
                    </Box>
                    </Header>
      }
    >  
             <Box  padding="m">
		   Your session is about to expire in 1 minute due to inactivity. Please choose 'Stay Logged in' to continue your session. Otherwise, you will be logged off automatically.
		   </Box>
    </Modal>
            </Container>
               
            <Container
                disableHeaderPaddings
                id="history"
                header={
                   <Header>
                       <Box variant="h3" fontWeight="bold" fontSize="heading-s"  padding ={{top:"xs",left:"xl",bottom:"xs"}}>
                          Uploaded Files List
                      </Box>
                      </Header>
                }
            >   
                <Box  padding="l">
                <div>
                { (historyCount===0) ? (<p>No files uploaded yet.</p>) : (
                <List list={historyList}/>)
                }
                </div>
                </Box>
            </Container>
  
  
            
           
         <Container disableHeaderPaddings header={  <Header>
                       <Box variant="h3" fontWeight="bold" fontSize="heading-s"  padding ={{top:"xs",left:"xl",bottom:"xs"}}>
                          Sample Information
                      </Box>
                      </Header>
           
         }>
      <form onSubmit={e => e.preventDefault()}>
        <Form
          variant="embedded"
          actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link">
              Cancel
            </Button>
            <Button variant="primary">Submit</Button>
          </SpaceBetween>
        }
		header={
            <Header><Box variant="h3" fontWeight="bold" fontSize="heading-s" padding ={{top:"xs",left:"xl",bottom:"xs"}}>
                        Sample Information
                    </Box></Header>
          }
		>
		
	
         
		<div class ="form-container">
		    	<SpaceBetween direction="vertical" alignItems="start" size="xl">
        <SpaceBetween direction="horizontal" size="xxl">
             
           	<div class ="label-container">
           	    <Box padding={{ top: "m" }}>
            <FormField label="Sample Source">   </FormField>  
            </Box>
            </div>
              <Box padding={{ left: "xxl" }}>
            <div class ="input-container">
				<SelectField 
>
    <option value="Sample Source 1">Sample Source 1</option>
    <option value="Sample Source 2">Sample Source 2</option>
    <option value="Sample Source 3">Sample Source 3</option>
    <option value="Sample Source 4">Sample Source 4</option>
    <option value="Sample Source 5">Sample Source 5</option>
    <option value="Sample Source 6">Sample Source 6</option>
</SelectField>
    </div>
    </Box>
          </SpaceBetween>
          <SpaceBetween direction="horizontal" size="xxl">
             
           	<div class ="label-container">
           	    <Box padding={{ top: "m" }}>
            <FormField label="Sample Type">   </FormField>  
            </Box>
            </div>
              <Box padding={{ left: "xxl" }}>
            <div class ="input-container">
				<SelectField
>
    <option value="Sample Type 1">Sample Type 1</option>
    <option value="Sample Type 2">Sample Type 2</option>
    <option value="Sample Type 3">Sample Type 3</option>
    <option value="Sample Type 4">Sample Type 4</option>
    <option value="Sample Type 5">Sample Type 5</option>
    <option value="Sample Type 6">Sample Type 6</option>
</SelectField>
    </div>
    </Box>
          </SpaceBetween>
           <SpaceBetween direction="horizontal" size="xxl">           
           	<div class ="label-container">
           	    <Box padding={{ right: "s" }}>
            <FormField label="If other, please specify">   </FormField>  
            </Box>
            </div>
              <Box padding={{ left: "s" }}>
            <div class ="input-container">
			<Input 
      onChange={({ detail }) => setValue1(detail.value)}
      value={value1}
    />
    </div>
    </Box>
          </SpaceBetween>
          
    
           <SpaceBetween direction="horizontal" size="xxl">
             
           	<div class ="label-container">
           	    <Box padding={{ top: "m" }}>
            <FormField label="Sample Media">   </FormField>  
            </Box>
            </div>
              <Box padding={{ left: "xxl" }}>
            <div class ="input-container">
				<SelectField
>
    <option value="Sample Media 1">Sample Media 1</option>
    <option value="Sample Media 2">Sample Media 2</option>
    <option value="Sample Media 3">Sample Media 3</option>
    <option value="Sample Media 4">Sample Media 4</option>
    <option value="Sample Media 5">Sample Media 5</option>
    <option value="Sample Media 6">Sample Media 6</option>
</SelectField>
    </div>
    </Box>
          </SpaceBetween>
          
           <SpaceBetween direction="horizontal" size="xxl">           
           	<div class ="label-container">
           	    <Box padding={{ right: "s" }}>
            <FormField label="If other, please specify">   </FormField>  
            </Box>
            </div>
              <Box padding={{ left: "s" }}>
            <div class ="input-container">
			<Input
      onChange={({ detail }) => setValue2(detail.value)}
      value={value2}
    />
    </div>
    </Box>
          </SpaceBetween>
           <SpaceBetween direction="horizontal" size="xxl">
             
           	<div class ="label-container">
           	    <Box padding={{ right: "s" }}>
            <FormField label="Date and time of specimen selection">   </FormField>  
            </Box>
            </div>
              <Box padding={{ left: "s" }}>
            <div class ="input-container">
			 <SpaceBetween direction="horizontal" size="s">
			<DatePicker
        onChange={({ detail }) => setDateValue(detail.value)}
        value={dateValue}
        openCalendarAriaLabel={selectedDate =>
          "Choose specimen collection date" +
          (selectedDate
            ? `, selected date is ${selectedDate}`
            : "")
        }
        placeholder="YYYY/MM/DD"
      />
            <TimeInput
      onChange={({ detail }) => setTimeValue(detail.value)}
      value={timeValue}
      format="hh:mm"
      placeholder="hh:mm"
    />
	 </SpaceBetween>
    </div>
    </Box>
          </SpaceBetween>
          
         <SpaceBetween direction="horizontal" size="xxl">
             
           	<div class ="label-container">
           	    <Box padding={{ top:"m" }}>
            <FormField label="Specimen storage temperature prior to standard of care
testing">   </FormField>  
            </Box>
            </div>
              <Box padding={{ left: "xxl" }}>
            <div class ="input-container">
				<SelectField
>
    <option value="4°C">4°C</option>
    <option value="5°C">5°C</option>
    <option value="6°C">6°C</option>
    <option value="7°C">7°C</option>
    <option value="8°C">8°C</option>
    <option value="9°C">9°C</option>
</SelectField>
    </div>
    </Box>
          </SpaceBetween>
          
           <SpaceBetween direction="horizontal" size="xxl">
             
           	<div class ="label-container">
           	    <Box padding={{ right: "s" }}>
            <FormField label="Date and time of Standard of Care test">   </FormField>  
            </Box>
            </div>
              <Box padding={{ left: "s" }}>
            <div class ="input-container">
			 <SpaceBetween direction="horizontal" size="s">
			<DatePicker
        onChange={({ detail }) => setDateValue1(detail.value)}
        value={dateValue1}
        openCalendarAriaLabel={selectedDate =>
          "Choose specimen collection date" +
          (selectedDate
            ? `, selected date is ${selectedDate}`
            : "")
        }
        placeholder="YYYY/MM/DD"
      />
            <TimeInput
      onChange={({ detail }) => setTimeValue1(detail.value)}
      value={timeValue1}
      format="hh:mm"
      placeholder="hh:mm"
    />
	 </SpaceBetween>
    </div>
    </Box>
          </SpaceBetween>
          
          
           <SpaceBetween direction="horizontal" size="xxl">
             
           	<div class ="label-container">
           	    <Box padding={{ right: "l" }}>
            <FormField label="Standard of Care test type">   </FormField>  
            </Box>
            </div>
              <Box padding={{ left: "l" }}>
            <div class ="input-container">
			 <SpaceBetween direction="vertical" size="s">
			 
			 <Checkbox
      onChange={({ detail }) =>
        setChecked(detail.checked)
      }
      checked={checked}
    >
      Biofire RESP 2.1
    </Checkbox>
	
	<Checkbox
      onChange={({ detail }) =>
        setChecked1(detail.checked)
      }
      checked={checked1}
    >
      Qiagen QIAstat Dx Resp SARS-CoV-2
    </Checkbox>
	
	<Checkbox
      onChange={({ detail }) =>
        setChecked2(detail.checked)
      }
      checked={checked2}
    >
      GenMark ePlex RPP2
    </Checkbox>
    
    	<Checkbox
      onChange={({ detail }) =>
        setChecked3(detail.checked)
      }
      checked={checked3}
    >
      Luminex NxTAG RP Panel
    </Checkbox>
    
    	<Checkbox
      onChange={({ detail }) =>
        setChecked4(detail.checked)
      }
      checked={checked4}
    >
      Other (Please Specify)
    </Checkbox>
			
	 </SpaceBetween>
    </div>
    </Box>
          </SpaceBetween>
          
           <SpaceBetween direction="horizontal" size="xxl">           
           	<div class ="label-container">
           	    <Box padding={{ right: "s" }}>
            <FormField label="If other, please specify SOC test name">   </FormField>  
            </Box>
            </div>
              <Box padding={{ left: "s" }}>
            <div class ="input-container">
			<Input
      onChange={({ detail }) => setValue(detail.value)}
      value={value}
      placeholder="SOC test name"
    />
    </div>
    </Box>
          </SpaceBetween>
          
           <SpaceBetween direction="horizontal" size="xxl">
             
           	<div class ="label-container">
           	    <Box padding={{ right: "l" }}>
            <FormField label="Biofire RESP 2.1 POSITIVE result ONLY">   </FormField>  
            </Box>
            </div>
              <Box padding={{ left: "l" }}>
            <div class ="input-container">
			 <SpaceBetween direction="vertical" size="s">
			 
			 <Checkbox
      onChange={({ detail }) =>
        setChecked5(detail.checked)
      }
      checked={checked5}
    >
      Adenovirus
    </Checkbox>
	
	<Checkbox
      onChange={({ detail }) =>
        setChecked6(detail.checked)
      }
      checked={checked6}
    >
      Coronavirus 229E
    </Checkbox>
    
    	<Checkbox
      onChange={({ detail }) =>
        setChecked20(detail.checked)
      }
      checked={checked20}
    >
      Coronavirus HKU1
    </Checkbox>
	
	<Checkbox
      onChange={({ detail }) =>
        setChecked7(detail.checked)
      }
      checked={checked7}
    >
      Coronavirus NL63
    </Checkbox>
    
    	<Checkbox
      onChange={({ detail }) =>
        setChecked8(detail.checked)
      }
      checked={checked8}
    >
      Coronavirus OC43
    </Checkbox>
    
    	<Checkbox
      onChange={({ detail }) =>
        setChecked9(detail.checked)
      }
      checked={checked9}
    >
      Severe Acute Respiratory Virus Coronavirus 2(SARS Cov-2)
    </Checkbox>
	
	
	 <Checkbox
      onChange={({ detail }) =>
        setChecked10(detail.checked)
      }
      checked={checked10}
    >
      Human Metapneumovirus
    </Checkbox>
	
	<Checkbox
      onChange={({ detail }) =>
        setChecked11(detail.checked)
      }
      checked={checked11}
    >
      Human Rhinovirus/Enterovirus
    </Checkbox>
	
	<Checkbox
      onChange={({ detail }) =>
        setChecked12(detail.checked)
      }
      checked={checked12}
    >
      Influenza A
    </Checkbox>
    
    	<Checkbox
      onChange={({ detail }) =>
        setChecked13(detail.checked)
      }
      checked={checked13}
    >
      Influenza A/H1
    </Checkbox>
    
    	<Checkbox
      onChange={({ detail }) =>
        setChecked14(detail.checked)
      }
      checked={checked14}
    >
     Influenza A/H1-2009
    </Checkbox>
	
	
	 <Checkbox
      onChange={({ detail }) =>
        setChecked15(detail.checked)
      }
      checked={checked15}
    >
      Influenza A/H3
    </Checkbox>
	
	<Checkbox
      onChange={({ detail }) =>
        setChecked16(detail.checked)
      }
      checked={checked16}
    >
      Influenza A Equivocal
    </Checkbox>
	
	<Checkbox
      onChange={({ detail }) =>
        setChecked17(detail.checked)
      }
      checked={checked17}
    >
      Influenza A (no subtype detected)
    </Checkbox>
    
    	<Checkbox
      onChange={({ detail }) =>
        setChecked18(detail.checked)
      }
      checked={checked18}
    >
      Influenza A H1 Equivocal
    </Checkbox>
    
    	<Checkbox
      onChange={({ detail }) =>
        setChecked19(detail.checked)
      }
      checked={checked19}
    >
      Influenza A H3 Equivocal
    </Checkbox>
	
	
			
	 </SpaceBetween>
    </div>
    </Box>
          </SpaceBetween>
          
        
          
          
         

          
          
          </SpaceBetween>
          
          </div>
          
        
          
        </Form>
      </form>
    </Container>
            

            
            <div class ="footer">
        <Text className = "textstyle" color={tokens.colors.white}>
          &copy; 2023 Cepheid. | Version 1.0
        </Text>
      </div>
        </SpaceBetween>
        </div>
    );
};

function App() {
    const { tokens } = useTheme();
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const [status, setStatus] = useState(false);

    const handleLogout = (uploadProgressStatus) => {
        setStatus(uploadProgressStatus);

    };

    const components = {
        SignIn: {
            Header() {
                return (
                    <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Log In
          <div className="line-3">
            <hr/>
        </div>
        </Heading>

                );
            }
        },
    };


    const formFields = {
        confirmResetPassword: {
            password: {
                innerEndComponent: (
                    <FieldGroupIconButton ariaLabel="" 
        >
          <div class="tooltip">
          <MdInfo />
		  <span class="tooltiptext">Password must contain atleast 1 uppercase , 1 lowercase , 1 special character and 1 number.</span>
		  </div>
        </FieldGroupIconButton>)
            },
        },
        forceNewPassword: {
            password: {
                innerEndComponent: (
                    <FieldGroupIconButton ariaLabel="" 
        >
          <div class="tooltip">
          <MdInfo />
		  <span class="tooltiptext">Password must contain atleast 1 uppercase , 1 lowercase , 1 special character and 1 number.</span>
		  </div>
        </FieldGroupIconButton>)
            },
        },

    };



    //Internationalization of default texts and labels
    I18n.putVocabulariesForLanguage('en', {
        'Sign in': 'Log in', // Button label
        'Signing in': 'Logging in',
        'Back to Sign In': 'Back to Log In',
        'Password does not conform to policy: Password must have symbol characters': 'Password does not conform to policy: Password must have special characters',
        'Invalid session for the user, session is expired.': 'User session has expired. Click on \'Back to Log In\' to try again.',
        'Username/client id combination not found.': 'Username not found.',
        'Attempt limit exceeded, please try after some time.': 'Attempt limit exceeded, please try after 60 mins with new verification code.'

    });

    async function currentAuthUser() {
        const user = await Auth.currentAuthenticatedUser();
        console.log('current auth user', user);
        return user;
    }

    async function recordSignOutbyUser(username) {
        const timeElapsed = Date.now();
        const today = new Date(timeElapsed);
        console.log('ScowUserLogOut : ', username + ' ' + ' ' + today.toUTCString());
        await Analytics.record({
            name: "ScowUserLogOut",
            attributes: {
                userLogOut: username + ' | ' + today.toUTCString(),
            },
            immediate: true,
        });
    }

    const navbarItemClick = async (e) => {
        console.log('dropdown click : ', e);
        const user = await currentAuthUser();
        console.log('username : ', user.username);
        if (e.detail.id === 'signout') {
            await recordSignOutbyUser(user.username);
            await Auth.signOut({ global: true }).then(() => {
                window.location.reload();
            });
        }
    };

    return (
        <div>
         {authStatus !== 'authenticated' ?  (<div>
            <div class="topnav">
            
            <img id="logoImage" alt="Cepheid" src="./cepheid_logo_scow.png"/>
            <Heading id = "titlenav"
        level={3} > Cepheid Secure Clinical Online Workspace </Heading>
        
            </div>
             <Authenticator components={components} formFields={formFields}>
             </Authenticator>
             <div class ="footer">
        <Text className = "textstyle" color={tokens.colors.white}>
          &copy; 2023 Cepheid. | Version 1.0
        </Text>
      </div>
        </div> 
        
        ) : 
       ( <Authenticator components={components}>
            
            {({signOut, user}) => (
                <>      
                    <div id="navbar" class ="uploadtopnav" style={{fontSize: 'body-l !important', position: 'sticky', top: 0, zIndex: 1002}}>
                          <Box
     padding={{bottom:"xxxs"}}
    >
                         <TopNavigation
                            identity={{
                                href: "#",
                                title: <Box variant="h3" fontWeight="bold" fontSize="heading-m"  padding={{ left: "m" ,top:"xxl"}}>
                            Cepheid Secure Clinical Online Workspace
                                </Box>,
                                logo: {
                                    src: "./cepheid_logo_scow.png",
                                    alt: "Cepheid Secure Clinical Online Workspace"
                                }
                            }}
                            utilities={[
                                {
                                    type: "menu-dropdown",
                                    text: user.username,
                                    iconName: "user-profile",
                                    onItemClick: navbarItemClick,
                                    disabled: status,
                                    items: [
                                        {id: "signout", text: "Log Out" }
                                    ]
                                } 
                            ]}
                            i18nStrings={{
                                searchIconAriaLabel: "Search",
                                searchDismissIconAriaLabel: "Close search",
                                overflowMenuTriggerText: "More"
                            }}
                        />
                        </Box>
                    </div>
                    <AppLayout
                        content={<Content onSelectLogOut={handleLogout}/>}
                        headerSelector='#navbar'
                        navigationHide = {navigationHide}
                        toolsHide = {toolsHide}
                    />
                </>
            )}
        </Authenticator>)
         }
        </div>
    );
}

export default App;
